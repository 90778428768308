import development from './envs/development';
import local from './envs/local';
import production from './envs/production';
import shared from './envs/shared';
import staging from './envs/staging';

const CONFIG_ENV =
  (process.env.REACT_APP_CONFIG_USE_EXTERNAL_API as EnvironmentType) ||
  (process.env.REACT_APP_CONFIG_ENV as EnvironmentType) ||
  'local';

const environments = {
  local,
  development,
  staging,
  production,
};

export type EnvironmentType = 'local' | 'development' | 'staging' | 'production';

type ConfigType = {
  CONFIG_ENV?: EnvironmentType;

  DATA_REFRESH_INTERVAL: number;

  GOOGLE_OAUTH_CLIENT_ID: string;

  SQUARE_CLIENT_ID: string;
  SQUARE_CALLBACK_URL?: string;

  SITE_API_BASE_URL?: string;

  STRIPE_PUBLISHABLE_KEY: string;

  HELP_URL: string;
};

const Configurator: ConfigType = Object.freeze({
  CONFIG_ENV,
  ...shared,
  ...environments[CONFIG_ENV],
});
export default Configurator;
