import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconPlusPurple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect width={24} height={24} fill="#5862F7" rx={12} />
    <path stroke="#fff" strokeLinecap="round" strokeWidth={3} d="M5 12h14M12 5v14" />
  </svg>
);
export default SvgIconPlusPurple;
