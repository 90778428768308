import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconCaretBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="currentColor" d="m17 14.5-5-5-5 5z" />
  </svg>
);
export default SvgIconCaretBlack;
