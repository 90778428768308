import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconSearchBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M2.778 10.766a7.989 7.989 0 1 1 15.977 0 7.989 7.989 0 0 1-15.977 0M10.766.778C5.25.778.778 5.25.778 10.766c0 5.517 4.472 9.989 9.988 9.989 2.28 0 4.38-.763 6.061-2.048l3.009 3.001a1 1 0 1 0 1.412-1.416l-2.96-2.953a9.95 9.95 0 0 0 2.467-6.573c0-5.516-4.472-9.988-9.989-9.988"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconSearchBlack;
