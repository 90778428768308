import { useState } from 'react';

import ArrowRightIcon from '@m/assets/svg/arrow-right-white';
import CloseIcon from '@m/assets/svg/close-rounded';
import LocationIcon from '@m/assets/svg/icon-location-black';
import MoonIcon from '@m/assets/svg/icon-moon-black';
import VisitorIcon from '@m/assets/svg/icon-transient-black';
import { showAlert } from '@m/state/alert';
import { useUnit } from 'effector-react';
import { DateTime } from 'luxon';

import { HospitalityService } from 'apps/request/services';
import { intakeStore } from 'apps/request/state/intake';
import { fetchOpenHospitalityStays } from 'apps/request/state/stays';
import { hideAppModal } from 'apps/request/state/ui';
import { HospitalityStayStatus, HospitalityStayType, StaySiteType } from 'apps/request/types';

import { showRequestSuccessAlert } from '../../Alerts/RequestSuccess/RequestSuccess';
import {
  ContentSection,
  EditBtn,
  Footer,
  FormContainer,
  FormLocationBanner,
  FormMainHeader,
  FormSection,
  Input,
  LocationAddress,
  LocationName,
  MainHeaderText,
  OptionText,
  SelectorOption,
  Slider,
  StayHeading,
  StaySelector,
  StaySubheading,
  FormButton,
} from '../Forms.styled';

function SendToGarageForm() {
  const { intakePartnerDetails } = useUnit(intakeStore);

  const [stayType, setStayType] = useState<HospitalityStayType>(HospitalityStayType.Overnight);
  const [driverFirstName, setDriverFirstName] = useState('');
  const [driverLastName, setDriverLastName] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [hotelTicketNumber, setHotelTicketNumber] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetValues = () => {
    setDriverFirstName('');
    setDriverLastName('');
    setRoomNumber('');
    setHotelTicketNumber('');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (intakePartnerDetails && !!intakePartnerDetails.siteId) {
      const formValues = {
        intakePartnerId: intakePartnerDetails.id,
        stayType,
        siteId: intakePartnerDetails.siteId,
        active: true,
        visitStart: DateTime.utc().toMillis(),
        status: HospitalityStayStatus.PickUpRequested,
        driverFirstName,
        driverLastName,
        guestFirstName: driverFirstName,
        guestLastName: driverLastName,
        hotelTicketNumber,
        hotelName: intakePartnerDetails.name,
        roomNumber,
        siteType: StaySiteType.OffSite,
      };

      const response = await HospitalityService.createNewStay(formValues);

      if (response.success) {
        if (response?.data?.id) {
          const requestPickUp = await HospitalityService.requestPickup(response.data.id);

          if (!requestPickUp.success) {
            showAlert({ type: 'error', label: 'Error Requesting Pickup' });
          }
        }

        // Seeing if we can hack this into returninug an updated list quicker than the next polling interval
        setTimeout(() => {
          if (intakePartnerDetails) {
            fetchOpenHospitalityStays(intakePartnerDetails.id).then((result) => {
              if (result.success) {
                resetValues();
                hideAppModal();
                showRequestSuccessAlert({ label: 'Send to Park Request Sent' });
              } else {
                showAlert({ type: 'error', label: 'Error fetching visits' });
                setIsSubmitting(false);
              }
            });
          }
        }, 2000);
      } else {
        showAlert({ type: 'error', label: 'Error making request' });
        setIsSubmitting(false);
      }
    } else {
      showAlert({ type: 'error', label: 'No SiteId found for this intake partner' });
      setIsSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <FormLocationBanner>
        <LocationIcon />
        <div>
          <LocationName>{intakePartnerDetails?.name}</LocationName>
          <LocationAddress>
            {intakePartnerDetails?.address}, {intakePartnerDetails?.city},{' '}
            {intakePartnerDetails?.state} {intakePartnerDetails?.zipcode}
          </LocationAddress>
        </div>
      </FormLocationBanner>

      <ContentSection>
        <FormMainHeader>
          <EditBtn type="button">Edit</EditBtn>
          <MainHeaderText>New Valet Request</MainHeaderText>
          <CloseIcon css="cursor: pointer" onClick={() => hideAppModal()} />
        </FormMainHeader>

        <FormSection>
          <StayHeading>Select the type of stay</StayHeading>
          <StaySubheading>
            This will help us bill the correct rate for the customer&apos;s visit.
          </StaySubheading>

          <StaySelector>
            <SelectorOption
              type="button"
              whichOpt={HospitalityStayType.Overnight}
              onClick={() => setStayType(HospitalityStayType.Overnight)}
            >
              <OptionText isActive={stayType === HospitalityStayType.Overnight}>
                <MoonIcon width={24} />
                <div>Overnight Guest</div>
              </OptionText>
            </SelectorOption>

            <SelectorOption
              type="button"
              whichOpt={HospitalityStayType.Transient}
              onClick={() => setStayType(HospitalityStayType.Transient)}
            >
              <OptionText isActive={stayType === HospitalityStayType.Transient}>
                <VisitorIcon width={24} />
                <div>Visitor</div>
              </OptionText>
            </SelectorOption>
            <Slider selectedOpt={stayType} />
          </StaySelector>
        </FormSection>

        <FormSection>
          <StayHeading>
            {stayType === HospitalityStayType.Overnight && 'Enter the name on hotel reservation'}
            {stayType === HospitalityStayType.Transient && 'Enter the name of the driver'}
          </StayHeading>
          <StaySubheading>
            This will help us bill the correct rate for the customer&apos;s visit.
          </StaySubheading>

          <Input
            type="text"
            name="driverFirstName"
            id="driverFirstName"
            placeholder="First name"
            value={driverFirstName}
            onChange={(e) => {
              setDriverFirstName(e.target.value.replace(/[^a-zA-Z]/g, ''));
            }}
          />

          <Input
            type="text"
            name="driverLastName"
            id="driverLastName"
            placeholder="Last name"
            value={driverLastName}
            onChange={(e) => {
              setDriverLastName(e.target.value.replace(/[^a-zA-Z]/g, ''));
            }}
          />
        </FormSection>

        {stayType === HospitalityStayType.Overnight && (
          <FormSection>
            <StayHeading>Enter room number (optional)</StayHeading>
            <StaySubheading>
              This will help us link the reservation back to the parking pass.
            </StaySubheading>

            <Input
              type="text"
              name="roomNumber"
              id="roomNumber"
              placeholder="Room number"
              value={roomNumber}
              onChange={(e) => {
                setRoomNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
              }}
            />
          </FormSection>
        )}

        <FormSection>
          <StayHeading>Ticket number</StayHeading>
          <StaySubheading>
            Enter the number on the hotel ticket once placed on dashboard of car.
          </StaySubheading>

          <Input
            type="text"
            name="hotelTicketNumber"
            id="hotelTicketNumber"
            placeholder="# Type in number"
            value={hotelTicketNumber}
            onChange={(e) => {
              setHotelTicketNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
            }}
          />
        </FormSection>
      </ContentSection>

      <Footer>
        <FormButton
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitting || !driverFirstName || !driverLastName || !hotelTicketNumber}
        >
          <span>{isSubmitting ? 'Sending Request...' : 'Send Vehicle to Garage Request'}</span>
          <ArrowRightIcon />
        </FormButton>
      </Footer>
    </FormContainer>
  );
}

export default SendToGarageForm;
