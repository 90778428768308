import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconPaymentGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    fill="none"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      fill="#0F8F4D"
      d="M2 16q-.824 0-1.412-.588A1.93 1.93 0 0 1 0 14V2Q0 1.176.588.588A1.93 1.93 0 0 1 2 0h16q.824 0 1.413.588Q20 1.175 20 2v12q0 .825-.587 1.412A1.93 1.93 0 0 1 18 16zm0-8h16V4H2z"
    />
  </svg>
);
export default SvgIconPaymentGreen;
