import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconLocationBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="17"
    fill="none"
    viewBox="0 0 15 17"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M0 7.07a7.07 7.07 0 1 1 11.426 5.568.8.8 0 0 1-.118.142l-3.756 3.515a.74.74 0 0 1-1.017-.004l-3.698-3.508a.8.8 0 0 1-.112-.136A7.06 7.06 0 0 1 0 7.07M7.07 8a1.358 1.358 0 1 0 0-2.717A1.358 1.358 0 0 0 7.07 8"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconLocationBlack;
