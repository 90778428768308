import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconMoonBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.48 3.167c.268 0 .537.015.799.055A9.5 9.5 0 0 0 9.896 9.5c0 2.335.87 4.568 2.383 6.278-.261.04-.53.055-.8.055A6.34 6.34 0 0 1 5.146 9.5a6.34 6.34 0 0 1 6.333-6.333m0-1.584A7.92 7.92 0 0 0 3.562 9.5a7.92 7.92 0 0 0 7.916 7.917c1.441 0 2.795-.396 3.959-1.07A7.9 7.9 0 0 1 11.479 9.5a7.9 7.9 0 0 1 3.959-6.848 7.9 7.9 0 0 0-3.959-1.069"
    />
  </svg>
);
export default SvgIconMoonBlack;
