import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconNoPaymentRed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="#DF795F"
      d="m.72 3.058 1.164 1.156c-.158.206-.269.467-.293.752v.135l-.008 1.837h3.024L6.192 8.52H1.583v5.122c0 .633.46 1.14 1.045 1.203l.12.008h9.776l1.584 1.584H2.611c-1.385 0-2.525-1.164-2.612-2.629V4.982c0-.744.277-1.425.72-1.932zm15.668-.87c1.385 0 2.525 1.163 2.612 2.628v8.827c0 .744-.277 1.425-.72 1.932l-1.14-1.148c.15-.182.245-.412.269-.657l.008-.127V8.52h-6.191L9.642 6.937h7.775V5.102c0-.689-.46-1.259-1.045-1.322l-.12-.008H6.477L4.893 2.188zm-1.299 9.5c.412 0 .744.356.744.791a.8.8 0 0 1-.158.491l-1.283-1.283z"
    />
    <path stroke="#DF795F" strokeLinecap="round" strokeWidth={1.667} d="m1.188 1 16.625 16.625" />
  </svg>
);
export default SvgIconNoPaymentRed;
