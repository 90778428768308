import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconMoonFilledBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="#000"
      d="M11.48 1.584c1.44 0 2.794.395 3.957 1.068A7.9 7.9 0 0 0 11.48 9.5a7.9 7.9 0 0 0 3.959 6.848 7.9 7.9 0 0 1-3.959 1.069A7.92 7.92 0 0 1 3.563 9.5a7.92 7.92 0 0 1 7.916-7.916"
    />
  </svg>
);
export default SvgIconMoonFilledBlack;
