import * as React from 'react';
import type { SVGProps } from 'react';

const SvgOffsetMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    fill="none"
    viewBox="0 0 22 20"
    {...props}
  >
    <rect width={22} height={3} fill="#000" rx={1.5} />
    <rect width={22} height={3} y={8.5} fill="#000" rx={1.5} />
    <rect width={22} height={3} y={17} fill="#000" rx={1.5} />
  </svg>
);
export default SvgOffsetMenu;
