import { useState } from 'react';

import ArrowRightIcon from '@m/assets/svg/arrow-right-white';
import CloseIcon from '@m/assets/svg/close-rounded';
import BackIcon from '@m/assets/svg/icon-back-black';
import LocationIcon from '@m/assets/svg/icon-location-black';
import { useUnit } from 'effector-react';
import Fuse from 'fuse.js';

import { intakeStore } from 'apps/request/state/intake';
import { staysStore } from 'apps/request/state/stays';
import { hideAppModal } from 'apps/request/state/ui';
import { HospitalityStay, HospitalityStayStatus, VisitCardVariant } from 'apps/request/types';

import VisitCard from '../../VisitCard';
import {
  ContentSection,
  Footer,
  FormContainer,
  FormLocationBanner,
  FormMainHeader,
  FormSection,
  Input,
  LocationAddress,
  LocationName,
  MainHeaderText,
  SearchResultsContainer,
  Separator,
  StayHeading,
  FormButton,
} from '../Forms.styled';

const parkedFirstSort = (a: HospitalityStay, b: HospitalityStay) => {
  if (
    a.hospitalityStay.status.name === HospitalityStayStatus.Parked &&
    b.hospitalityStay.status.name !== HospitalityStayStatus.Parked
  ) {
    return -1;
  }
  if (
    a.hospitalityStay.status.name !== HospitalityStayStatus.Parked &&
    b.hospitalityStay.status.name === HospitalityStayStatus.Parked
  ) {
    return 1;
  }
  return 0;
};

function SearchForm() {
  const { intakePartnerDetails } = useUnit(intakeStore);
  const { hospitalityStays } = useUnit(staysStore);

  const [driverName, setDriverName] = useState('');
  const [licensePlateText, setlicensePlateText] = useState('');
  const [roomNumber, setRoomNumber] = useState('');
  const [hotelTicketNumber, setHotelTicketNumber] = useState('');

  const [searchResults, setSearchResults] = useState<HospitalityStay[] | null>(null);
  const [showSearchResults, setShowSearchResult] = useState(false);

  const searchSubmit = () => {
    const fuseOpts: any = {
      search: '',
      config: {
        keys: [],
        isCaseSensitive: false,
        threshold: 0.3,
      },
    };

    if (driverName) {
      fuseOpts.config.keys = [
        'hospitalityStay.guestFirstName',
        'hospitalityStay.guestLastName',
        'hospitalityStay.driverFirstName',
        'hospitalityStay.driverLastName',
      ];

      const searchTerms = driverName.trim().split(' ');

      if (searchTerms.length === 1) {
        fuseOpts.search = {
          $or: [
            { 'hospitalityStay.guestFirstName': searchTerms[0] },
            { 'hospitalityStay.driverFirstName': searchTerms[0] },
            { 'hospitalityStay.guestLastName': searchTerms[0] },
            { 'hospitalityStay.driverLastName': searchTerms[0] },
          ],
        };
      } else if (searchTerms.length === 2) {
        fuseOpts.search = {
          $or: [
            { 'hospitalityStay.guestFirstName': searchTerms[0] },
            { 'hospitalityStay.guestLastName': searchTerms[1] },
            { 'hospitalityStay.driverFirstName': searchTerms[0] },
            { 'hospitalityStay.driverLastName': searchTerms[1] },
          ],
        };
      } else {
        fuseOpts.search = searchTerms.join(' ');
      }
    }

    if (licensePlateText) {
      fuseOpts.config.keys = ['vehicle.licensePlate.text'];
      fuseOpts.search = licensePlateText;
    }

    if (roomNumber) {
      fuseOpts.config.keys = ['hospitalityStay.roomNumber'];
      fuseOpts.search = roomNumber;
    }

    if (hotelTicketNumber) {
      fuseOpts.config.keys = ['hospitalityStay.hotelTicketNumber'];
      fuseOpts.search = hotelTicketNumber;
    }

    const fuse = new Fuse(hospitalityStays, { ...fuseOpts.config });

    const results = fuse.search(fuseOpts.search);

    setSearchResults(results.map((result) => result.item));
    setShowSearchResult(true);
  };

  return (
    <FormContainer>
      <FormLocationBanner>
        <LocationIcon />
        <div>
          <LocationName>{intakePartnerDetails?.name}</LocationName>
          <LocationAddress>
            {intakePartnerDetails?.address}, {intakePartnerDetails?.city},{' '}
            {intakePartnerDetails?.state} {intakePartnerDetails?.zipcode}
          </LocationAddress>
        </div>
      </FormLocationBanner>

      <ContentSection>
        <FormMainHeader>
          {showSearchResults && (
            <BackIcon
              css="cursor: pointer"
              onClick={() => {
                setShowSearchResult(false);
                setSearchResults(null);
              }}
            />
          )}
          <MainHeaderText>Search for a Guest</MainHeaderText>
          <CloseIcon css="cursor: pointer" onClick={() => hideAppModal()} />
        </FormMainHeader>

        <FormSection>
          <StayHeading>{showSearchResults ? 'Search results' : 'Search'}</StayHeading>

          {showSearchResults && searchResults ? (
            <SearchResultsContainer>
              {searchResults
                ?.sort(parkedFirstSort)
                .map((item) => (
                  <VisitCard
                    key={item.hospitalityStay.id}
                    details={item}
                    variant={VisitCardVariant.SearchResult}
                  />
                ))}
            </SearchResultsContainer>
          ) : (
            <>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="First name or Last name"
                value={driverName}
                disabled={!!licensePlateText || !!hotelTicketNumber || !!roomNumber}
                onChange={(e) => {
                  setDriverName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''));
                }}
              />

              <Separator>Or</Separator>

              <Input
                type="text"
                name="licensePlate"
                id="licensePlate"
                placeholder="License plate"
                value={licensePlateText}
                disabled={!!driverName || !!hotelTicketNumber || !!roomNumber}
                onChange={(e) => {
                  setlicensePlateText(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
                }}
              />

              <Separator>Or</Separator>

              <Input
                type="text"
                name="hotelTicketNumber"
                id="hotelTicketNumber"
                placeholder="Ticket number"
                value={hotelTicketNumber}
                disabled={!!driverName || !!licensePlateText || !!roomNumber}
                onChange={(e) => {
                  setHotelTicketNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
                }}
              />

              <Separator>Or</Separator>

              <Input
                type="text"
                name="roomNumber"
                id="roomNumber"
                placeholder="Room number"
                value={roomNumber}
                disabled={!!driverName || !!licensePlateText || !!hotelTicketNumber}
                onChange={(e) => {
                  setRoomNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
                }}
              />
            </>
          )}
        </FormSection>
      </ContentSection>

      {showSearchResults ? null : (
        <Footer>
          <FormButton
            type="button"
            variant="black"
            onClick={searchSubmit}
            disabled={
              !driverName && !licensePlateText && !roomNumber && !driverName && !hotelTicketNumber
            }
          >
            <span>Search</span>
            <ArrowRightIcon />
          </FormButton>
        </Footer>
      )}
    </FormContainer>
  );
}

export default SearchForm;
