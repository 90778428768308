import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconDoorBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    fill="none"
    viewBox="0 0 8 13"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M1 0a1 1 0 0 0-1 1v12h8V1a1 1 0 0 0-1-1zm5 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconDoorBlack;
