import * as React from 'react';
import type { SVGProps } from 'react';

const SvgArrowRightWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="#fff" strokeWidth={3} d="m10.952 3.086 8.919 8.92-8.92 8.918M20 12.058H2" />
  </svg>
);
export default SvgArrowRightWhite;
