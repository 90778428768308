import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconCheckCircleGreenWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#fff"
      d="M16.001 2.667C8.641 2.667 2.668 8.64 2.668 16s5.973 13.333 13.333 13.333S29.335 23.36 29.335 16 23.36 2.667 16 2.667"
    />
    <path
      fill="#0F8F4D"
      d="m13.335 18.893 7.84-7.84c.52-.52 1.374-.52 1.894 0s.52 1.36 0 1.88l-8.787 8.787c-.52.52-1.36.52-1.88 0l-3.453-3.453c-.52-.52-.52-1.36 0-1.88s1.36-.52 1.88 0z"
    />
  </svg>
);
export default SvgIconCheckCircleGreenWhite;
