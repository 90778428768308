import { useMemo } from 'react';

import BackIcon from '@m/assets/svg/icon-back-black';
import ParkedIcon from '@m/assets/svg/icon-request-parked-black';
import SearchIcon from '@m/assets/svg/icon-search-black';
import MenuIcon from '@m/assets/svg/offset-menu';
import { useUnit } from 'effector-react';

import { staysStore } from 'apps/request/state/stays';
import { setDashboardView, showAppMenu, uiStore, showAppModal } from 'apps/request/state/ui';
import { DashboardView, HospitalityStayStatus } from 'apps/request/types';

import SearchForm from '../Forms/SearchForm';

import {
  HeaderContainer,
  LocationName,
  pointer,
  OptionText,
  Slider,
  SliderContainer,
  SliderOption,
  ViewChanger,
  MobileParkButton,
  SearchSection,
  MobileParkedSelectorAlt,
} from './AddHeader.styled';

type Props = {
  name: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
};

function AppHeader({ name, address, city, state, zipcode }: Props) {
  const { dashboardView, showMobileViewSelector, displayAddressInHeader } = useUnit(uiStore);
  const { hospitalityStays } = useUnit(staysStore);
  const parkedCount = useMemo(
    () =>
      hospitalityStays.filter(
        (item) => item.hospitalityStay.status.name === HospitalityStayStatus.Parked,
      ).length,
    [hospitalityStays],
  );

  const changeDashboardView = (changeTo: DashboardView) => {
    setDashboardView({ view: changeTo });
  };

  const handleSearchClick = () => {
    showAppModal({ content: <SearchForm /> });
  };

  return (
    <>
      <HeaderContainer>
        <MenuIcon onClick={() => showAppMenu()} css={pointer} />
        <LocationName>
          {name}
          {displayAddressInHeader &&
            address &&
            city &&
            state &&
            zipcode &&
            ` - ${address}, ${city}, ${state} ${zipcode}`}
        </LocationName>
        <SearchSection>
          {showMobileViewSelector && (
            <MobileParkButton active={dashboardView === DashboardView.PARKED}>
              <ParkedIcon onClick={() => setDashboardView({ view: DashboardView.PARKED })} />
            </MobileParkButton>
          )}
          <SearchIcon onClick={handleSearchClick} css={pointer} />
        </SearchSection>
      </HeaderContainer>

      <ViewChanger>
        {showMobileViewSelector && dashboardView !== DashboardView.PARKED && (
          <SliderContainer>
            <SliderOption
              type="button"
              which={DashboardView.TOPARK}
              onClick={() => changeDashboardView(DashboardView.TOPARK)}
              isMobile
            >
              <OptionText isActive={dashboardView === DashboardView.TOPARK}>
                Send to Park
              </OptionText>
            </SliderOption>
            <SliderOption
              type="button"
              which={DashboardView.TOPROP}
              onClick={() => changeDashboardView(DashboardView.TOPROP)}
              isMobile
            >
              <OptionText isActive={dashboardView === DashboardView.TOPROP}>
                Send to Property
              </OptionText>
            </SliderOption>
            <Slider selectedOption={dashboardView} isMobile />
          </SliderContainer>
        )}

        {showMobileViewSelector && dashboardView === DashboardView.PARKED && (
          <MobileParkedSelectorAlt>
            <BackIcon
              width={28}
              height={28}
              onClick={() => {
                setDashboardView({ view: DashboardView.TOPARK });
              }}
              css={pointer}
            />
            Parked &bull; {parkedCount}
          </MobileParkedSelectorAlt>
        )}

        {!showMobileViewSelector && (
          <SliderContainer>
            <SliderOption
              type="button"
              which={DashboardView.TODO}
              onClick={() => changeDashboardView(DashboardView.TODO)}
            >
              <OptionText isActive={dashboardView === DashboardView.TODO}>To Do</OptionText>
            </SliderOption>
            <SliderOption
              type="button"
              which={DashboardView.PARKED}
              onClick={() => changeDashboardView(DashboardView.PARKED)}
            >
              <OptionText isActive={dashboardView === DashboardView.PARKED}>
                Parked Guests
              </OptionText>
            </SliderOption>
            <Slider selectedOption={dashboardView} />
          </SliderContainer>
        )}
      </ViewChanger>
    </>
  );
}

export default AppHeader;
