import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconTransientBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.133 11.084h-5.55v1.583h5.55v2.375l3.159-3.166-3.159-3.167zm4.734-.792V7.917h5.55V6.334h-5.55V3.959L8.71 7.126z"
    />
  </svg>
);
export default SvgIconTransientBlack;
