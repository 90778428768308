import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconCheckGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="#538A58" d="m8.795 15.875-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41z" />
  </svg>
);
export default SvgIconCheckGreen;
