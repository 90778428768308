import { useEffect, useState } from 'react';

import ArrowRightIcon from '@m/assets/svg/arrow-right-white';
import CloseIcon from '@m/assets/svg/close-rounded';
import CheckBoxChecked from '@m/assets/svg/icon-checkbox-checked-black';
import CheckBoxUnchecked from '@m/assets/svg/icon-checkbox-unchecked-black';
import LocationIcon from '@m/assets/svg/icon-location-black';
import MoonIcon from '@m/assets/svg/icon-moon-black';
import PaymentIcon from '@m/assets/svg/icon-payment-green';
import VisitorIcon from '@m/assets/svg/icon-transient-black';
import { showAlert } from '@m/state/alert';
import { useUnit } from 'effector-react';
import { DateTime } from 'luxon';

import { HospitalityService } from 'apps/request/services';
import { intakeStore } from 'apps/request/state/intake';
import { fetchOpenHospitalityStays } from 'apps/request/state/stays';
import { hideAppModal } from 'apps/request/state/ui';
import {
  HospitalityStay,
  HospitalityStayDetails,
  HospitalityStayStatus,
  HospitalityStayType,
} from 'apps/request/types';

import { showRequestSuccessAlert } from '../../Alerts/RequestSuccess/RequestSuccess';
import {
  ContentSection,
  EditBtn,
  FlexDiv,
  Footer,
  FormContainer,
  FormLocationBanner,
  FormMainHeader,
  FormSection,
  Input,
  LocationAddress,
  LocationName,
  MainHeaderText,
  StayHeading,
  FormButton,
  TwoInputContainer,
  PaymentConfirmed,
  PaymentMissing,
  ConfirmPaymentSection,
  RoomNumberContainer,
} from '../Forms.styled';

function isHospitalityStayType(value: string): value is HospitalityStayType {
  return Object.values(HospitalityStayType).includes(value as HospitalityStayType);
}

const sendToHotelEnabled = ({
  stayType,
  details,
}: {
  stayType: HospitalityStayType;
  details: HospitalityStay;
}) =>
  (stayType === HospitalityStayType.Overnight || stayType === HospitalityStayType.Transient) &&
  details.hospitalityStay.status.name === HospitalityStayStatus.Parked;

function getStayIcon(stayType: string) {
  if (stayType === HospitalityStayType.Overnight) return <MoonIcon css="margin-right: 10px;" />;
  return <VisitorIcon css="margin-right: 10px;" />;
}

/*
 * Comment - On why use setTimeout and vist list cache issue
 *
 * For BE Caching reasons 'result' did not return the updated list.
 * The supposed updated visit is still in the old status even though in 'response' the status is updated.
 *
 * setTimout for 2 sec to minimize the percieved latencey for the req transition after modal closes and
 * potential UI issues that may come with that (user able to make another, bad, req etc.)
 */

function GuestDetailsForm({ details }: { details: HospitalityStay }) {
  const { intakePartnerDetails } = useUnit(intakeStore);

  const [updatedFormResults, setUpdatedFormResult] = useState<HospitalityStayDetails | null>(null);

  const [driverFirstName, setDriverFirstName] = useState(
    updatedFormResults?.driverFirstName || details.hospitalityStay.driverFirstName,
  );
  const [driverLastName, setDriverLastName] = useState(
    updatedFormResults?.driverLastName || details.hospitalityStay.driverLastName,
  );
  const [roomNumber, setRoomNumber] = useState(
    updatedFormResults?.roomNumber || details.hospitalityStay?.roomNumber || '',
  );
  const [hotelTicketNumber, setHotelTicketNumber] = useState(
    updatedFormResults?.hotelTicketNumber || details.hospitalityStay.hotelTicketNumber,
  );
  const [stayType, setStayType] = useState<HospitalityStayType>(() => {
    if (updatedFormResults?.stayType?.name === HospitalityStayType.Overnight)
      return HospitalityStayType.Overnight;
    if (details.hospitalityStay.stayType.name === HospitalityStayType.Overnight)
      return HospitalityStayType.Overnight;
    return HospitalityStayType.Transient;
  });

  const [inEditMode, setInEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [hasConfirmedPayment, setHasConfirmedPayment] = useState(false);

  useEffect(() => {
    if (isHospitalityStayType(details.hospitalityStay.stayType.name)) {
      setStayType(details.hospitalityStay.stayType.name);
    }
  }, [details]);

  const resetValues = () => {
    setDriverFirstName(
      updatedFormResults?.driverFirstName || details.hospitalityStay.driverFirstName,
    );
    setDriverLastName(updatedFormResults?.driverLastName || details.hospitalityStay.driverLastName);
    setRoomNumber(updatedFormResults?.roomNumber || details.hospitalityStay?.roomNumber || '');
    setHotelTicketNumber(
      updatedFormResults?.hotelTicketNumber || details.hospitalityStay.hotelTicketNumber,
    );

    // Determine stayType based on updatedFormResults or details
    if (updatedFormResults?.stayType?.name === HospitalityStayType.Overnight) {
      setStayType(HospitalityStayType.Overnight);
    } else if (details.hospitalityStay.stayType.name === HospitalityStayType.Overnight) {
      setStayType(HospitalityStayType.Overnight);
    } else {
      setStayType(HospitalityStayType.Transient);
    }

    setInEditMode(false);
  };

  const handleSubmitSave = async () => {
    setIsSubmitting(true);
    setInEditMode(false);

    if (intakePartnerDetails && !!stayType) {
      const formValues = {
        driverFirstName,
        driverLastName,
        hotelTicketNumber,
        hotelName: intakePartnerDetails.name,
        roomNumber,
        guestFirstName: driverFirstName,
        guestLastName: driverLastName,
      };

      // See: Comment - On why use setTimeout and vist list cache issue

      const response = await HospitalityService.editStay({
        hospitalityStayUuid: details.hospitalityStay.id,
        formValues,
      });

      if (response.success) {
        setUpdatedFormResult(response?.data);
        setTimeout(() => {
          fetchOpenHospitalityStays(intakePartnerDetails.id);
        }, 2000);
        setIsSubmitting(false);
      } else {
        showAlert({ type: 'error', label: 'Error saving values' });
        setIsSubmitting(false);
      }
    }
  };

  const handleSendToHotelRequest = async () => {
    setIsSubmitting(true);

    // See: Comment - On why use setTimeout and vist list cache issue

    const response = await HospitalityService.requestDropoff(details.hospitalityStay.id);

    if (response.success && intakePartnerDetails) {
      setTimeout(() => {
        fetchOpenHospitalityStays(intakePartnerDetails.id).then((result) => {
          if (result.success) {
            hideAppModal();
            showRequestSuccessAlert({ label: 'Send to Property Request Sent' });
          }
        });
      }, 2000);
    } else {
      showAlert({ type: 'error', label: 'Error making request' });
      setIsSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <FormLocationBanner>
        <LocationIcon />
        <div>
          <LocationName>{intakePartnerDetails?.name}</LocationName>
          <LocationAddress>
            {intakePartnerDetails?.address}, {intakePartnerDetails?.city},{' '}
            {intakePartnerDetails?.state} {intakePartnerDetails?.zipcode}
          </LocationAddress>
        </div>
      </FormLocationBanner>

      <ContentSection>
        <FormMainHeader>
          <EditBtn
            type="button"
            visible
            onClick={() => {
              if (isSubmitting) return;

              if (inEditMode) {
                resetValues();
              } else {
                setInEditMode(true);
              }
            }}
          >
            {inEditMode ? 'Cancel' : 'Edit'}
          </EditBtn>
          <MainHeaderText>Guest Details</MainHeaderText>
          <CloseIcon
            css="cursor: pointer"
            onClick={() => {
              if (!isSubmitting) {
                hideAppModal();
              }
            }}
          />
        </FormMainHeader>

        {sendToHotelEnabled({ stayType, details }) && (
          <FormSection>
            <FormButton
              type="button"
              onClick={handleSendToHotelRequest}
              disabled={
                isSubmitting ||
                !driverFirstName ||
                !driverLastName ||
                !hotelTicketNumber ||
                (stayType === HospitalityStayType.Overnight &&
                  !details.hospitalityStay.roomNumber &&
                  !updatedFormResults?.roomNumber) ||
                (stayType === HospitalityStayType.Transient && !hasConfirmedPayment)
              }
            >
              <span>Send to Hotel</span>
              <ArrowRightIcon />
            </FormButton>

            {stayType === HospitalityStayType.Overnight && details.hospitalityStay?.roomNumber && (
              <PaymentConfirmed>
                <PaymentIcon width={20} />
                Payment on file
              </PaymentConfirmed>
            )}

            {stayType === HospitalityStayType.Overnight &&
              !details.hospitalityStay?.roomNumber &&
              !updatedFormResults?.roomNumber && (
                <PaymentMissing>
                  Room number needed to link payment before vehicle can be dropped off.
                </PaymentMissing>
              )}

            {stayType === HospitalityStayType.Transient && !hasConfirmedPayment && (
              <PaymentMissing>Source of payment has not been captured.</PaymentMissing>
            )}

            {stayType === HospitalityStayType.Transient && (
              <ConfirmPaymentSection>
                {hasConfirmedPayment && (
                  <CheckBoxChecked
                    width={24}
                    css="cursor: pointer;"
                    onClick={() => setHasConfirmedPayment(false)}
                  />
                )}
                {!hasConfirmedPayment && (
                  <CheckBoxUnchecked
                    width={24}
                    css="cursor: pointer;"
                    onClick={() => setHasConfirmedPayment(true)}
                  />
                )}
                I have instructed the guest on how to provide payment.
              </ConfirmPaymentSection>
            )}
          </FormSection>
        )}

        <FormSection>
          <StayHeading addMargin>
            {stayType === HospitalityStayType.Overnight && 'Name on hotel reservation'}
            {stayType === HospitalityStayType.Transient && 'Name of the driver'}
          </StayHeading>

          <Input
            type="text"
            name="driverFirstName"
            id="driverFirstName"
            placeholder="First name"
            value={driverFirstName}
            onChange={(e) => {
              setDriverFirstName(e.target.value.replace(/[^a-zA-Z]/g, ''));
            }}
            disabled={!inEditMode}
          />

          <Input
            type="text"
            name="driverLastName"
            id="driverLastName"
            placeholder="Last name"
            value={driverLastName}
            onChange={(e) => {
              setDriverLastName(e.target.value.replace(/[^a-zA-Z]/g, ''));
            }}
            disabled={!inEditMode}
          />
        </FormSection>

        <TwoInputContainer>
          <div>
            <StayHeading addMargin>Ticket number</StayHeading>

            <Input
              type="text"
              name="hotelTicketNumber"
              id="hotelTicketNumber"
              placeholder="# Type in number"
              value={hotelTicketNumber}
              onChange={(e) => {
                setHotelTicketNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
              }}
              disabled={!inEditMode}
            />
          </div>

          {stayType === HospitalityStayType.Overnight && (
            <RoomNumberContainer>
              <StayHeading addMargin>Room number</StayHeading>

              <Input
                type="text"
                name="roomNumber"
                id="roomNumber"
                placeholder="Room number"
                value={roomNumber}
                onChange={(e) => {
                  setRoomNumber(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
                }}
                disabled={!inEditMode}
                hasError={sendToHotelEnabled({ stayType, details }) && !roomNumber}
              />
            </RoomNumberContainer>
          )}
        </TwoInputContainer>

        <FormSection>
          <StayHeading addMargin>Type of stay</StayHeading>
          <FlexDiv>
            {getStayIcon(details.hospitalityStay.stayType.name)}{' '}
            {details.hospitalityStay.stayType.name === HospitalityStayType.Overnight
              ? 'Overnight guest'
              : 'Visitor'}
          </FlexDiv>
        </FormSection>

        <FormSection>
          <StayHeading addMargin>Visit started</StayHeading>
          <FlexDiv>
            {DateTime.fromMillis(details.hospitalityStay.visitStart).toFormat(
              "h:mm a 'on' MMM d, yyyy",
            )}
          </FlexDiv>
        </FormSection>

        {!!details.vehicle && (
          <FormSection>
            <StayHeading addMargin>Vehicle Details</StayHeading>
            <FlexDiv>
              {`${details.vehicle.licensePlate.text}, ${details.vehicle.licensePlate.state.name}`}
            </FlexDiv>
          </FormSection>
        )}
      </ContentSection>

      <Footer>
        <FormButton
          type="button"
          variant="black"
          onClick={handleSubmitSave}
          disabled={
            !inEditMode || isSubmitting || !driverFirstName || !driverLastName || !hotelTicketNumber
          }
        >
          <span>Save Changes</span>
        </FormButton>
      </Footer>
    </FormContainer>
  );
}

export default GuestDetailsForm;
