import TimerIcon from '@m/assets/svg/timer-filled';
import { DateTime } from 'luxon';

import { Container } from './Timestamp.styled';

type Props = {
  time: number;
};

const URGENCY_MINS = 45;

const isUrgentCheck = (time: number): boolean => {
  const requestTime = DateTime.fromMillis(time);
  const urgentTime = DateTime.now().minus({ minutes: URGENCY_MINS });
  return requestTime < urgentTime;
};

const timeSince = (time: number): string => {
  const requestTime = DateTime.fromMillis(time);
  const now = DateTime.now();
  const diff = now.diff(requestTime, ['hours', 'minutes']).toObject();
  const hours = Math.floor(diff.hours || 0);
  const minutes = Math.floor(diff.minutes || 0);

  if (hours === 0 && minutes <= 0) {
    return 'Just now';
  }

  if (hours === 0 && minutes > 0) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
  }

  if (hours > 0 && minutes === 0) {
    return `${hours} hr${hours > 1 ? 's' : ''} ago`;
  }

  return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''} ago`;
};

function Timestamp({ time }: Props) {
  const urgentCheck = isUrgentCheck(time);
  return (
    <Container isUrgent={urgentCheck}>
      <TimerIcon width={14} />
      {timeSince(time)}
    </Container>
  );
}

export default Timestamp;
