import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconTicketBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="13"
    fill="none"
    viewBox="0 0 9 13"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M.5 11.857v-1.746h.666V9.39H.5V1.143C.5.512 1.012 0 1.643 0h1.524c0 .798.597 1.445 1.333 1.445S5.833.798 5.833 0h1.524C7.988 0 8.5.512 8.5 1.143v8.246h-.667v.722H8.5v1.746C8.5 12.488 7.988 13 7.357 13H5.833c0-.798-.597-1.444-1.333-1.444S3.167 12.202 3.167 13H1.643A1.143 1.143 0 0 1 .5 11.857M1.833 9.39v.722h1.334V9.39zm2 0v.722h1.334V9.39zm2 .722V9.39h1.334v.722z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIconTicketBlack;
