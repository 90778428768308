import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconInfoPurple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="#5F59FF"
      d="M11.459 7.292h2.083v2.083h-2.083zm0 4.167h2.083v6.25h-2.083zM12.5 2.084C6.75 2.084 2.084 6.75 2.084 12.5S6.75 22.917 12.5 22.917 22.917 18.25 22.917 12.5 18.25 2.084 12.5 2.084m0 18.75c-4.594 0-8.333-3.74-8.333-8.334s3.74-8.333 8.333-8.333c4.594 0 8.334 3.74 8.334 8.333 0 4.594-3.74 8.334-8.334 8.334"
    />
  </svg>
);
export default SvgIconInfoPurple;
